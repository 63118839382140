import Image from "next/image";
import React from "react";

const ProductThumbnail = (props) => {
    const image = props.product && props.product.thumbnail ? `${process.env.contentStorage}/card-images/${props.product.thumbnail}` : 'https://content.krit.ro/placeholder-card-images/img_missing.jpg';

return (
      <div className={`${props.className}`}>

            <Image
                 src={image}
                 lazyBoundary="200px"
                 unoptimized={true}
                 priority={!!props.priority}
                 loading={!!props.priority ? 'eager' : 'lazy'}
                 placeholder="empty"
                 layout="fill"
                 objectFit="contain"/>
      </div>
 )
}

export default ProductThumbnail;
